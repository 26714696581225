import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Layout from '../components/Layout';
import TextBanner from '../components/TextBanner';

const NotFoundPage = () => {
  const {
    datoCmsSite: {
      globalSeo: { titleSuffix },
    },
    datoCmsNotFound: { title, bannerText, bannerLink },
  } = useStaticQuery(graphql`
    query NotFoundPageQuery {
      datoCmsSite {
        globalSeo {
          titleSuffix
        }
      }
      datoCmsNotFound {
        title
        bannerText
        bannerLink {
          text
          page {
            ...LinkFragment
          }
        }
      }
    }
  `);

  return (
    <Layout locale={'en'}>
      <HelmetDatoCms title={title} titleTemplate={`%s${titleSuffix}`} />
      <main>
        <TextBanner
          title="404"
          heading={title}
          text={bannerText}
          link={bannerLink}
          fullHeight={true}
        />
      </main>
    </Layout>
  );
};

export default NotFoundPage;
